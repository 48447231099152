/*
 *  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *  Created by Kaveen Abeyrathne for StudPro 5.0 as a place for students to look
 *  into What they can learn,companies to understand what to expect. This website
 *  was developed as a first step to taking StudPro to a higher elevation. You are
 *  free to update,share & modify the work for IEEE purposes ONLY.
 *
 *  Content of the source code is not authorized to use for any commercial purpose.
 *
 *  SuiCxDe007 | https://github.com/SuiCxDe007 | kaveenmadawa@gmail.com
 *
 *  ~ Copyright © 2023 SuiCxDe
 *  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

import React from "react";
import YouTube from "react-youtube";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

/**
 *
 * @returns handles individual YT Videos
 */

const VideosComponent = (props) => {
  var indents = [];

  const opts = {
    width: "100%"
  };
  for (const x in props.video) {
    if (x !== "null") {
      indents.push(
        <MDBCol key={Math.random()} xs={12} md={6} lg={4} className="mb-3 mt-3">
          <YouTube
            style={{ objectFit: "contain", minHeight: 10 }}
            opts={opts}
            videoId={props.video[x]} // defaults -> ''
            id={props.video[x]} // defaults -> ''
            // defaults -> noop
          />
          <span style={{ fontSize: "0.9rem" }}>{x}</span>
        </MDBCol>
      );
    }
  }
  return <MDBRow>{indents}</MDBRow>;
};

export default VideosComponent;
